<template>
  <section class="onboarding h-100">
    <div class="flex h-screen">
      <div class="m-auto lg:ml-40">
        <h3 class="text align-self-center me-auto p-2">
          Define the <strong>What</strong>
        </h3>
        <h3 class="text align-self-center me-auto p-2">
          Discover the <strong>Who</strong>
        </h3>
        <h3 class="text align-self-center me-auto p-2">
          Decide the <strong>When</strong>
        </h3>
        <h3 class="text align-self-center me-auto p-2 mb-5">
          We’ll help with <strong>How</strong>
        </h3>

        <div class="pl-3">
          <router-link
            :to="{name: 'signin'}"
            class="btn btn-white align-self-center me-auto mt-8 mr-3"
          >
            Sign In
          </router-link>
          <router-link
            :to="{name: 'signup'}"
            class="btn btn-primary align-self-center me-auto mt-8"
          >
            Get Started Now
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Onboarding'
}
</script>

<style scoped>
.onboarding {
  background-image: url('~@/assets/images/onboarding.jpg');
  background-size: cover;
  background-position: 50%;
  height: 100vh!important;
}
h3 {
  font-family: 'Helvetica';
  font-size: 54px;
  line-height: 70px;
  color: #FFFFFF;
  letter-spacing: 1px;
  font-weight: 300;
}
h3 > strong {
  font-weight: bold;
}
a.btn-primary {
  background-color: #FF6600;
  color: #FFFFFF;
  padding: 10px;
  border: 1px solid #FF6600;
  box-sizing: border-box;
  border-radius: 35px;
}
a.btn-white {
  background-color: transparent;
  color: #FFFFFF;
  padding: 10px;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 35px;
}
</style>
